import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

// eslint-disable-next-line
export const MuiTextField = (theme: Theme): Components['MuiTextField'] => ({
  defaultProps: {
    margin: 'dense',
    variant: 'filled',
    fullWidth: true,
  },
  styleOverrides: {
    root: {},
  },
});
