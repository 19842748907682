import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { SearchInputs } from '../pages/ContactList';

type ContactState = {
  filters: SearchInputs;
};

const slice = createSlice({
  name: 'contact',
  initialState: { filters: { keyword: '', categories: [], spaces: [], stakeholders: [] } } as ContactState,
  reducers: {
    setFilters: (state, { payload: filters }: PayloadAction<SearchInputs>) => {
      state.filters = filters;
    },
  },
});

export const { setFilters } = slice.actions;
export const contactReducer = slice.reducer;

export const getFilters = (state: RootState) => state.contact.filters;
