import { Breakpoint } from '@mui/material';
import { Layout } from 'react-grid-layout';

import { Widget, WidgetProperties, WidgetType } from '../types';

export const validateWidget = (w: Partial<Widget<WidgetProperties>>) => !!w;
export const validateWidgetType = (w: Partial<Widget<WidgetProperties>>) =>
  validateWidget(w) && Object.values(WidgetType).includes(w.type!);
export const validateAddWidget = (widgets: Widget<WidgetProperties>[], w: Widget<WidgetProperties>) =>
  validateWidget(w) && widgets.findIndex(el => el.id === w.id) === -1;

export const filterLayouts = (layouts: { [b in Breakpoint]?: Layout[] }, ids: string[]) => {
  const obj: { [b in Breakpoint]?: Layout[] } = {};

  Object.keys(layouts).forEach(key => {
    if (Array.isArray(layouts[key as Breakpoint]))
      obj[key as Breakpoint] = layouts[key as Breakpoint]!.filter(el => !ids.includes(el.i));
  });
  return obj;
};
