import { isRejected, Middleware } from '@reduxjs/toolkit';
import { history } from 'index';
import { baseApi } from 'store/api/baseApi';

export const unauthorizedMiddleware: Middleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (isRejected(action) && action?.payload?.status === 401) {
      localStorage.removeItem('accessToken');
      history.push('/login');
      dispatch(baseApi.util.resetApiState());
    }
    return next(action);
  };
