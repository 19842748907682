import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetFavoritesProperties, WidgetFavoritesState } from './types';
import { CommonWidgetGetters } from '../types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetFavoritesState = {
  widgets: [],
  deleteWidgetsIds: [],
};
const slice = createSlice({
  name: 'widgetFavorites',
  initialState,
  reducers: {
    ...commonReducers<WidgetFavoritesState, WidgetFavoritesProperties>(),
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetFavoritesActions = slice.actions;

export const widgetFavoritesReducer = slice.reducer;

export const widgetFavoritesGetters: CommonWidgetGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetFavorites.widgets,
};
