import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cumulio from 'cumulio';
import { RootState } from 'store/store';
import { WidgetCumulioState, WidgetCumulioGetters, WidgetCumulioProperties } from './types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetCumulioState = {
  widgets: [],
  deleteWidgetsIds: [],
  cumulioClient: null,
  cumulioConfig: null,
  cumulioDashboards: [],
};
const slice = createSlice({
  name: 'widgetCumulio',
  initialState,
  reducers: {
    ...commonReducers<WidgetCumulioState, WidgetCumulioProperties>(),
    initCumulio: (state, action: PayloadAction<any>) => {
      state.cumulioConfig = action.payload;
      state.cumulioClient = new Cumulio({
        api_key: action.payload.authKey,
        api_token: action.payload.authToken,
      });
    },
    setDashboards: (state, action: PayloadAction<any[]>) => {
      state.cumulioDashboards = action.payload;
    },
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetCumulioActions = { ...slice.actions };

export const widgetCumulioReducer = slice.reducer;

export const widgetCumulioGetters: WidgetCumulioGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetCumulio.widgets,
  getCumulioClient: (state: RootState) => state.widgets.widgetCumulio.cumulioClient,
  getCumulioConfig: (state: RootState) => state.widgets.widgetCumulio.cumulioConfig,
  getCumulioDashboards: (state: RootState) => state.widgets.widgetCumulio.cumulioDashboards,
};
