import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetShareProperties, WidgetShareState } from 'features/dashboard/store/widget/widgetShare/types';
import { CommonWidgetGetters } from '../types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetShareState = {
  widgets: [],
  deleteWidgetsIds: [],
};
const slice = createSlice({
  name: 'widgetShare',
  initialState,
  reducers: {
    ...commonReducers<WidgetShareState, WidgetShareProperties>(),
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetShareActions = slice.actions;

export const widgetShareReducer = slice.reducer;

export const widgetShareGetters: CommonWidgetGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetShare.widgets,
};
