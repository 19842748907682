import { createTheme } from '@mui/material';
import { MuiButton } from 'theme/components/MuiButton';
import { MuiCssBaseline } from 'theme/components/MuiCssBaseline';
import { MuiTypography } from 'theme/components/MuiTypography';
import { MuiDrawer } from 'theme/components/MuiDrawer';
import { MuiTextField } from 'theme/components/MuiTextField';
import { MuiFilledInput } from 'theme/components/MuiFilledInput';
import { MuiAutocomplete } from 'theme/components/MuiAutocomplete';
import { MuiChip } from 'theme/components/MuiChip';
import { MuiAccordion } from './components/MuiAccordion';

export const themeVariables = createTheme({
  palette: {
    primary: {
      main: '#3194a5',
      dark: '#1f889a',
      light: '#C9CECF',
      contrastText: '#1D1D1B',
      // #1f889a
      // 2a95a7
    },
    secondary: {
      main: '#cfa03b',
      light: '#288798',
      dark: '#404040',
      contrastText: '#979797',
    },
    background: {
      default: '#f2f8f9',
      paper: '#fff',
      light: '#dddddd',
    },
    focusArea: {
      patients: '#6872B7',
      rnd: '#45B7E6',
      policy: '#71C598',
    },
    text: {
      primary: '#000000',
      secondary: '#666',
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontSize: 13,
    fontWeightBold: 'bold',
    fontWeightMedium: 'bold',
    fontWeightLight: 'normal',
    fontWeightRegular: 'normal',
    fontFamily: 'FaktPro, serif',
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      lineHeight: '1.875rem',
    },
    subtitle2: {
      fontSize: '0.938rem',
      fontWeight: 'bold',
    },
    caption: {
      fontSize: '12px',
    },
  },
});

export const baseTheme = createTheme({
  ...themeVariables,
  components: {
    MuiCssBaseline: MuiCssBaseline(themeVariables),
    MuiDrawer: MuiDrawer(themeVariables),
    MuiTypography: MuiTypography(themeVariables),
    MuiTextField: MuiTextField(themeVariables),
    MuiFilledInput: MuiFilledInput(themeVariables),
    MuiChip: MuiChip(themeVariables),
    MuiButton,
    MuiAutocomplete: MuiAutocomplete(themeVariables),
    MuiAccordion: MuiAccordion(),
  },
});

declare module '@mui/material/styles' {
  interface Components {
    MuiDropzoneArea: any;
    MuiDropzonePreviewList: any;
  }

  interface Theme {
    status: {
      patients: React.CSSProperties['color'];
      rnd: React.CSSProperties['color'];
      policy: React.CSSProperties['color'];
    };
  }

  interface TypeBackground {
    default: string;
    paper: string;
    light: string;
  }

  interface PaletteColor {
    patients?: string;
    rnd?: string;
    policy?: string;
  }
  interface SimplePaletteColorOptions {
    patients?: string;
    rnd?: string;
    policy?: string;
  }

  interface Palette {
    focusArea: Palette['primary'];
  }
  interface PaletteOptions {
    focusArea: PaletteOptions['primary'];
  }

  interface ThemeOptions {
    status: {
      patients: React.CSSProperties['color'];
      rnd: React.CSSProperties['color'];
      policy: React.CSSProperties['color'];
    };
  }
}
