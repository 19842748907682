import React, { lazy, Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import { AppRoutes } from 'routes/AppRoutes';
import { Worker } from '@react-pdf-viewer/core';
import LogRocket from 'logrocket';
import { parsePayload } from 'features/auth/utils/jwt';
import { DefaultLoaderFallback } from '../Loader';

const Polling = lazy(() => import('features/monitor/components/Polling'));

export function App() {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init('xhufrx/iph-cug');
    const token = localStorage.getItem('accessToken');
    if (token) {
      const { sub } = parsePayload(token);
      LogRocket.identify(sub);
    }
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
      <Suspense fallback={<DefaultLoaderFallback />}>
        <CssBaseline />
        <AppRoutes />
        <Polling />
      </Suspense>
    </Worker>
  );
}
