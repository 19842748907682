import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endpoints } from 'features/page/store/pageService';
import { GENERAL_ITEM_ID } from 'features/core/store/sidebar';
import { RootState } from 'store/store';

type SpaceState = {
  // The id of the special "General" space with a dedicated menu item outside of "Working Groups"
  generalSpaceId: string | null;
  currentSpaceId: string | null;
};

const initialState = {
  generalSpaceId: null,
  currentSpaceId: null,
};

const slice = createSlice({
  name: 'space',
  initialState: initialState as SpaceState,
  reducers: {
    setGeneralSpaceId: (state, { payload: spaceId }: PayloadAction<string | null>) => {
      state.generalSpaceId = spaceId;
    },
    setCurrentSpaceId: (state, { payload: spaceId }: PayloadAction<string | null>) => {
      state.currentSpaceId = spaceId;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(endpoints.getPage.matchFulfilled, (state, action) => {
      if (action?.meta?.arg?.originalArgs === GENERAL_ITEM_ID) {
        state.generalSpaceId = action?.payload?.id || null;
      }
    });
  },
});

export const { setGeneralSpaceId, setCurrentSpaceId } = slice.actions;

export const spaceReducer = slice.reducer;

export const getGeneralSpaceId = (state: RootState) => state.space.generalSpaceId;

export const getHasAccessToGeneralSpace = createSelector(getGeneralSpaceId, generalSpaceId => generalSpaceId !== null);

export const getCurrentSpaceId = (state: RootState) => state.space.currentSpaceId;
