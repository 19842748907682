import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type PollingState = {
  isHealthy: boolean;
};

const initialState = {
  isHealthy: true,
};

const slice = createSlice({
  name: 'polling',
  initialState: initialState as PollingState,
  reducers: {
    setPolling: (state, { payload: isHealthy }: PayloadAction<boolean>) => {
      state.isHealthy = isHealthy;
    },
  },
});

export const { setPolling } = slice.actions;
export const pollingReducer = slice.reducer;
export const getPolling = (state: RootState) => state.polling.isHealthy;
