import React, { lazy, useLayoutEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router';

const Login = lazy(() => import('features/auth/components/Login'));
const ForgotPassword = lazy(() => import('features/auth/components/ForgotPassword'));
const PasswordReset = lazy(() => import('features/auth/components/PasswordReset'));
const AppAuthenticatedRoutes = lazy(
  () => import(/* webpackChunkName: "AppAuthenticatedRoutes" */ './AppAuthenticatedRoutes'),
);
const ProtectedRoute = lazy(() => import(/* webpackChunkName: "ProtectedRoute" */ './ProtectedRoute'));

export function AppRoutes() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<PasswordReset />} />
      <Route
        path="*"
        element={
          <ProtectedRoute authenticationPath="/login">
            <AppAuthenticatedRoutes />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
