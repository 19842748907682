import { Components } from '@mui/material/styles/components';

export const MuiAccordion = (): Components['MuiAccordion'] => ({
  styleOverrides: {
    root: {
      boxShadow: '0px 0px 5px -4px rgba(0, 0, 0, 0.5)',
      borderRadius: 0,
    },
  },
});
