import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Page } from 'features/page/store/pageService';
import { RootState } from 'store/store';

type SpaceState = {
  currentPage: Page | null;
  currentPageParentId: string | null;
};

const initialState = {
  currentPage: null,
  currentPageParentId: null,
};

const slice = createSlice({
  name: 'page',
  initialState: initialState as SpaceState,
  reducers: {
    setCurrentPage: (state, { payload: page }: PayloadAction<Page>) => {
      state.currentPage = page;
    },
    setCurrentPageParentId: (state, { payload: id }: PayloadAction<string | null>) => {
      state.currentPageParentId = id;
    },
  },
});

export const { setCurrentPage, setCurrentPageParentId } = slice.actions;

export const pageReducer = slice.reducer;

export const getCurrentPage = (state: RootState) => state.page.currentPage;

export const getCurrentPageId = createSelector(getCurrentPage, page => page?.id || null);

export const getCurrentPageParentId = (state: RootState) => state.page.currentPageParentId;
