import { TreeItem } from 'react-sortable-tree';

import { FocusAreaType } from 'features/core/components/FocusArea';

export type MenuItemChild = TreeItem | MenuItem;

export interface MenuItem {
  id: string;
  name: string;
  path: string; // unique
  longPath?: string; // unique
  focusArea: FocusAreaType;
  title?: string;
  lead?: any;
  subTitle?: string;
  iconComponentName?: IconComponentName;
  children?: MenuItemChild[];
  expanded?: boolean;
  isPage?: boolean;
  parent: string | null;
  weight?: number;
  favorite?: boolean;
  isHover?: boolean;
  createdAt: string;
  lastModifiedAt: string;
  defaultPath?: string;
  isPublished: boolean;
}

export type KeyOfMenuItem = keyof MenuItem;

export enum StaticMenuItemName {
  DASHBOARD = 'Dashboard',
  GENERAL = 'General',
  WORKING_GROUPS = 'Working Groups',
  MESSAGES = 'Messages',
  CONTACTS = 'Contacts',
  SETTINGS = 'Settings',
  DEFAULT_DASHBOARDS = 'Default Dashboards',
  PAGE_TEMPLATES = 'Page templates',
}

export type IconComponentName =
  | 'Dashboard'
  | 'General'
  | 'WorkOutline'
  | 'SmsOutlined'
  | 'PeopleOutline'
  | 'SettingsOutlined';

export interface SidebarState {
  menuItems: MenuItem[];
  currentMenuItem: MenuItem | null;
  isSubMenuOpen: boolean;
  isFetch: boolean;
}
