import { TreeItem } from 'react-sortable-tree';
import { MenuItem, StaticMenuItemName } from '../types';

export class SidebarCheck {
  static hasChildren(arr: MenuItem[] | TreeItem[] | undefined): boolean {
    return Array.isArray(arr) && !!arr.length;
  }

  static hasListExpandedItem(items: MenuItem[]): boolean {
    return items.findIndex(el => !!el.expanded) > -1;
  }

  static isDynamicPageOfStaticItem(name: StaticMenuItemName): boolean {
    return [
      StaticMenuItemName.GENERAL,
      StaticMenuItemName.WORKING_GROUPS,
      StaticMenuItemName.DEFAULT_DASHBOARDS,
      StaticMenuItemName.SETTINGS,
    ].includes(name);
  }

  static isStaticPageOfStaticItem(name: StaticMenuItemName): boolean {
    return [StaticMenuItemName.CONTACTS, StaticMenuItemName.DASHBOARD, StaticMenuItemName.MESSAGES].includes(name);
  }

  static isGeneralPage(name: StaticMenuItemName): boolean {
    return name === StaticMenuItemName.GENERAL;
  }
}
