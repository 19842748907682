import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiAutocomplete = (theme: Theme): Components['MuiAutocomplete'] => ({
  defaultProps: {
    ChipProps: {
      size: 'small',
      [theme.breakpoints.down('sm')]: {
        size: 'medium',
      },
    },
    autoHighlight: true,
    filterSelectedOptions: true,
    fullWidth: true,
  },
  styleOverrides: {
    root: {},
    input: {
      [theme.breakpoints.down('sm')]: {
        margin: 10,
      },
    },
  },
});
