import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

export interface SearchState {
  isSearchOpen: boolean;
}
const slice = createSlice({
  name: 'search',
  initialState: {
    isSearchOpen: false,
  },
  reducers: {
    setIsSearchOpen: (state: SearchState, action: PayloadAction<boolean>) => {
      state.isSearchOpen = action.payload;
    },
  },
});

export const searchActions = slice.actions;

export const searchReducer = slice.reducer;

export const searchGetters = {
  getIsSearchOpen: (state: RootState) => state.search.isSearchOpen,
};
