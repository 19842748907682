import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

// eslint-disable-next-line
export const MuiChip = (theme: Theme): Components['MuiChip'] => ({
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      borderRadius: '3px',
      [theme.breakpoints.down('sm')]: {
        width: '47%',
      },
    },
  },
});
