import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetClippingsGetters, WidgetClippingsState, WidgetClippingsProperties } from './types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetClippingsState = {
  widgets: [],
  deleteWidgetsIds: [],
  viewPage: 0,
  viewClippings: [],
};
const slice = createSlice({
  name: 'widgetClippings',
  initialState,
  reducers: {
    ...commonReducers<WidgetClippingsState, WidgetClippingsProperties>(),
    setViewPage: (state, action) => {
      state.viewPage = action.payload;
    },
    setViewClippings: (state, action) => {
      state.viewClippings = action.payload;
    },
    resetView: state => {
      state.viewPage = 0;
      state.viewClippings = [];
    },
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetClippingsActions = slice.actions;

export const widgetClippingsReducer = slice.reducer;

export const widgetClippingsGetters: WidgetClippingsGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetClippings.widgets,
  getViewPage: (state: RootState) => state.widgets.widgetClippings.viewPage,
  getViewClippings: (state: RootState) => state.widgets.widgetClippings.viewClippings,
};
