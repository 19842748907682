import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';

export type LoaderProps = {
  isLoading: boolean;
  fallback?: React.ReactElement;
  children: React.ReactElement;
  text?: string;
};

export function DefaultLoaderFallback({ loaderText }: any) {
  return (
    <Grid container alignContent="center" justifyContent="center" sx={{ height: '100%' }}>
      <Grid item xs={12} textAlign="center">
        <CircularProgress />
      </Grid>
      {loaderText && (
        <Grid item xs={12} textAlign="center">
          <Typography>{loaderText}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export function Loader({
  isLoading = false,
  text,
  fallback = <DefaultLoaderFallback loaderText={text} />,
  children,
}: LoaderProps) {
  return isLoading ? fallback : children;
}

Loader.defaultProps = {
  text: '',
};
