import { combineReducers, createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layouts } from 'react-grid-layout';
import { RootState } from 'store/store';
import {
  widgetRecentActivityGetters,
  widgetRecentActivityReducer,
  widgetRecentActivityActions,
} from 'features/dashboard/store/widget/widgetRecentActivity/slice';
import {
  widgetPushNewsGetters,
  widgetPushNewsReducer,
  widgetPushNewsActions,
} from 'features/dashboard/store/widget/widgetPushNews/slice';
import {
  widgetShareGetters,
  widgetShareReducer,
  widgetShareActions,
} from 'features/dashboard/store/widget/widgetShare/slice';
import {
  widgetMembersGetters,
  widgetMembersReducer,
  widgetMembersActions,
} from 'features/dashboard/store/widget/widgetMembers/slice';
import {
  widgetFavoritesGetters,
  widgetFavoritesReducer,
  widgetFavoritesActions,
} from 'features/dashboard/store/widget/widgetFavorites/slice';
import {
  widgetInfoGetters,
  widgetInfoReducer,
  widgetInfoActions,
} from 'features/dashboard/store/widget/widgetInfo/slice';
import {
  widgetTopicsGetters,
  widgetTopicsReducer,
  widgetTopicsActions,
} from 'features/dashboard/store/widget/widgetTopics/slice';
import { WidgetCommonState, Widget, WidgetState, WidgetType, NewInitializeObject, WidgetProperties } from '.';
import { widgetDocumentReducer, widgetDocumentGetters, widgetDocumentActions } from './widgetDocument/slice';
import { widgetWgReducer, widgetWgGetters, widgetWgActions } from './widgetWg/slice';
import { widgetTextReducer, widgetTextGetters, widgetTextActions } from './widgetText/slice';
import { widgetClippingsReducer, widgetClippingsGetters, widgetClippingsActions } from './widgetClippings/slice';
import { widgetNavigationReducer, widgetNavigationGetters, widgetNavigationActions } from './widgetNavigation/slice';
import { widgetCumulioReducer, widgetCumulioGetters, widgetCumulioActions } from './widgetCumulio/slice';
import { commonWidgetsExtraHandlers, CommonActionsList } from './common';
import { validateWidgetType, filterLayouts } from './utils';

// TODO the whole widget store is unnecessarily complex and inefficient in terms of performance and will need some major refactoring

const commonActionsList = new CommonActionsList({
  widgetDocumentActions,
  widgetTextActions,
  widgetClippingsActions,
  widgetNavigationActions,
  widgetCumulioActions,
  widgetWgActions,
  widgetRecentActivityActions,
  widgetPushNewsActions,
  widgetShareActions,
  widgetFavoritesActions,
  widgetMembersActions,
  widgetInfoActions,
  widgetTopicsActions,
});
const widgetInitialization = createAsyncThunk(
  'widget/initialization',
  async (data: Widget<WidgetProperties>[], { dispatch, getState }) => {
    const obj: NewInitializeObject = {
      [WidgetType.TEXT]: [],
      [WidgetType.INFO]: [],
      [WidgetType.TOPICS]: [],
      [WidgetType.DOCUMENT]: [],
      [WidgetType.CLIPPINGS]: [],
      [WidgetType.NAVIGATION]: [],
      [WidgetType.CUMULIO]: [],
      [WidgetType.WG]: [],
      [WidgetType.RECENT_ACTIVITY]: [],
      [WidgetType.PUSH_NEWS]: [],
      [WidgetType.SHARE]: [],
      [WidgetType.FAVORITES]: [],
      [WidgetType.MEMBERS]: [],
    };

    const pageId = (getState() as RootState).page.currentPage.id;

    const returnCondition = data.length > 0 && data.every(w => w.parent !== pageId);

    if (returnCondition) return;

    data.forEach(w => {
      if (validateWidgetType(w)) {
        obj[w.type].push(w as Widget<any>);
      }
    });

    Object.keys(obj).forEach(async t => {
      const actions = await commonActionsList.getActionsByWidget({ type: t as WidgetType });

      if (actions) {
        dispatch((actions as any).set(obj[t as WidgetType]));
      }
    });
  },
);
const widgetAdd = createAsyncThunk('widget/add', async (data: Widget<WidgetProperties>, { dispatch }) => {
  if (validateWidgetType(data)) {
    const actions = await commonActionsList.getActionsByWidget(data);

    if (actions) {
      dispatch((actions as any).add(data));
    }
  }
});
const widgetUpdate = createAsyncThunk('widget/update', async (data: Widget<WidgetProperties>, { dispatch }) => {
  if (validateWidgetType(data)) {
    const actions = await commonActionsList.getActionsByWidget(data);

    if (actions) {
      dispatch((actions as any).update(data));
    }
  }
});
const widgetDelete = createAsyncThunk('widget/delete', async (data: Widget<WidgetProperties>, { dispatch }) => {
  if (validateWidgetType(data)) {
    const actions = await commonActionsList.getActionsByWidget(data);

    if (actions) {
      dispatch((actions as any).delete(data.id));
    }
  }
});
const setMediaToUpload = createAsyncThunk(
  'widget/clear',
  async (
    data: { media: Array<File | undefined> | undefined; widget: Partial<Widget<WidgetProperties>> },
    { dispatch },
  ) => {
    if (validateWidgetType(data.widget)) {
      const actions = await commonActionsList.getActionsByWidget(data.widget);

      if (actions) {
        dispatch((actions as any).setMediaToUpload({ media: data.media }));
      }
    }
  },
);
const initialState: WidgetCommonState = {
  layouts: {},
  temporaryLayouts: {},
  isOpenFormModal: false,
  currentWidgetId: null,
  isFormLoading: false,
  isUpdateItemLoading: false,
  isDeleteItemLoading: false,
  isLoading: false,
  isItemLoading: false,
  uploadDocumentModal: {
    isOpen: false,
    widgetId: '',
    type: '',
    data: [],
  },
  widgetContentSizes: {},
  permissions: {
    common: [],
    current: [],
  },
};

const slice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setLayouts: (state: WidgetCommonState, action: PayloadAction<Layouts>) => {
      state.layouts = action.payload;
    },

    setTemporaryLayouts: (state: WidgetCommonState, action: PayloadAction<Layouts>) => {
      state.temporaryLayouts = action.payload;
    },
    setIsOpenFormModal: (state: WidgetCommonState, action: PayloadAction<boolean>) => {
      state.isOpenFormModal = action.payload;
    },
    setCurrentWidgetId: (state: WidgetCommonState, action: PayloadAction<string | null>) => {
      state.currentWidgetId = action.payload;
    },
    setIsFormLoading: (state: WidgetCommonState, action: PayloadAction<boolean>) => {
      state.isFormLoading = action.payload;
    },
    setIsUpdateItemLoading: (state: WidgetCommonState, action: PayloadAction<boolean>) => {
      state.isUpdateItemLoading = action.payload;
    },
    setIsDeleteItemLoading: (state: WidgetCommonState, action: PayloadAction<boolean>) => {
      state.isDeleteItemLoading = action.payload;
    },
    setIsItemLoading: (state: WidgetCommonState, action: PayloadAction<boolean>) => {
      state.isItemLoading = action.payload;
    },
    setUploadDocumentModal: (state, action) => {
      state.uploadDocumentModal = {
        ...action.payload,
        data: Object.values(action.payload.data),
      };
    },
    changeWidgetContentSize: (state, { payload }) => {
      const {
        widgetId,
        value: { contentHeight, contentWidth },
      } = payload;
      state.widgetContentSizes[widgetId] = {
        ...state.widgetContentSizes[widgetId],
        ...(contentHeight && { contentHeight }),
        ...(contentWidth && { contentWidth }),
      };
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(widgetInitialization.pending, state => {
      state.isLoading = true;
      commonWidgetsExtraHandlers.deleteLayouts(state);
    });
    builder.addCase(widgetInitialization.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(widgetInitialization.rejected, state => {
      state.isLoading = false;
    });

    commonActionsList.getAllActions().forEach((actions: any) => {
      if (!actions) {
        throw new Error(
          'An error caused by adding a new type to the `enum WidgetType` and not updating the `CommonActionsList`',
        );
      }

      builder.addCase(actions.set, commonWidgetsExtraHandlers.setLayouts);
      builder.addCase(actions.add, commonWidgetsExtraHandlers.addLayouts);
      builder.addCase(actions.update, commonWidgetsExtraHandlers.updateLayouts);
    });
  },
});
const widgetClear = createAsyncThunk('widget/clear', async (_, { dispatch, getState }) => {
  const allActions = commonActionsList.getAllActions();
  // @ts-ignore
  dispatch(slice.actions.setLayouts(getState().widgets.widget.layouts));
  dispatch(slice.actions.setTemporaryLayouts({}));
  allActions.forEach(actions => {
    dispatch((actions as any).clear());
  });
});

export const widgetsActions = {
  ...slice.actions,
  widgetInitialization,
  widgetAdd,
  widgetUpdate,
  widgetDelete,
  widgetClear,
  setMediaToUpload,
  widgetClippings: {
    setViewClippings: widgetClippingsActions.setViewClippings,
    setViewPage: widgetClippingsActions.setViewPage,
    resetView: widgetClippingsActions.resetView,
  },
  widgetDocument: {
    setViewDocuments: widgetDocumentActions.setViewDocuments,
    setViewPage: widgetDocumentActions.setViewPage,
    resetView: widgetDocumentActions.resetView,
  },
  widgetRecentActivity: {
    setViewDocuments: widgetRecentActivityActions.setViewDocuments,
    setViewPage: widgetRecentActivityActions.setViewPage,
    resetView: widgetRecentActivityActions.resetView,
  },
  widgetCumulio: {
    initCumulio: widgetCumulioActions.initCumulio,
    setDashboards: widgetCumulioActions.setDashboards,
  },
  widgetWg: {
    setIsOpenTopicsSettings: widgetWgActions.setIsOpenTopicsSettings,
    setActiveTopicFilters: widgetWgActions.setActiveTopicFilters,
  },
  widgetTopics: {
    setIsOpenTopicsSettings: widgetTopicsActions.setIsOpenTopicsSettings,
    setActiveTopicFilters: widgetTopicsActions.setActiveTopicFilters,
  },
};
export const widgetsReducer = combineReducers({
  widget: slice.reducer,
  widgetDocument: widgetDocumentReducer,
  widgetText: widgetTextReducer,
  widgetClippings: widgetClippingsReducer,
  widgetNavigation: widgetNavigationReducer,
  widgetCumulio: widgetCumulioReducer,
  widgetWg: widgetWgReducer,
  widgetRecentActivity: widgetRecentActivityReducer,
  widgetPushNews: widgetPushNewsReducer,
  widgetShare: widgetShareReducer,
  widgetFavorites: widgetFavoritesReducer,
  widgetMembers: widgetMembersReducer,
  widgetInfo: widgetInfoReducer,
  widgetTopics: widgetTopicsReducer,
});

const getWidgets = (state: RootState) => state.widgets;

const getFilteredWidgets = createSelector(getWidgets, widgets =>
  Object.values(widgets).reduce(
    (newArr, wState: WidgetState<Widget<WidgetProperties>>) =>
      newArr.concat((wState.widgets || []).filter(w => !wState.deleteWidgetsIds.includes(w.id))),
    [],
  ),
);

const getLayouts = createSelector(getWidgets, widgets => {
  let layouts;
  if (Object.keys(widgets.widget.temporaryLayouts).length) {
    layouts = widgets.widget.temporaryLayouts;
  } else {
    layouts = widgets.widget.layouts;
  }
  return filterLayouts(
    layouts,
    Object.values(widgets).reduce(
      (newArr, wState: WidgetState<Widget<WidgetProperties>>) => newArr.concat(wState.deleteWidgetsIds || []),
      [],
    ),
  );
});

export const widgetsGetters = {
  getIsLoading: (state: RootState) => state.widgets.widget.isLoading,
  getWidgets,
  getFilteredWidgets,
  getLayouts,
  getIsOpenFormModal: (state: RootState) => state.widgets.widget.isOpenFormModal,
  getCurrentWidgetId: (state: RootState) => state.widgets.widget.currentWidgetId,
  getMediaToUpload: (state: RootState) =>
    Object.values(state.widgets).find(wState => wState.mediaToUpload)?.mediaToUpload,
  getIsFormLoading: (state: RootState) => state.widgets.widget.isFormLoading,
  getIsUpdateItemLoading: (state: RootState) => state.widgets.widget.isUpdateItemLoading,
  getIsDeleteItemLoading: (state: RootState) => state.widgets.widget.isDeleteItemLoading,
  widgetDocument: widgetDocumentGetters,
  widgetText: widgetTextGetters,
  widgetClippings: widgetClippingsGetters,
  widgetNavigation: widgetNavigationGetters,
  widgetCumulio: widgetCumulioGetters,
  widgetWg: widgetWgGetters,
  widgetRecentActivity: widgetRecentActivityGetters,
  widgetPushNews: widgetPushNewsGetters,
  widgetShare: widgetShareGetters,
  widgetFavorites: widgetFavoritesGetters,
  widgetMembers: widgetMembersGetters,
  widgetInfo: widgetInfoGetters,
  permissions: (state: RootState) => state.widgets.widget.permissions,
  widgetTopics: widgetTopicsGetters,
};
