import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetPushNewsProperties, WidgetPushNewsState } from 'features/dashboard/store/widget/widgetPushNews/types';
import { CommonWidgetGetters } from '../types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetPushNewsState = {
  widgets: [],
  deleteWidgetsIds: [],
};
const slice = createSlice({
  name: 'widgetPushNews',
  initialState,
  reducers: {
    ...commonReducers<WidgetPushNewsState, WidgetPushNewsProperties>(),
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetPushNewsActions = slice.actions;

export const widgetPushNewsReducer = slice.reducer;

export const widgetPushNewsGetters: CommonWidgetGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetPushNews.widgets,
};
