import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetNavigationState, WidgetNavigationProperties } from './types';
import { CommonWidgetGetters } from '../types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetNavigationState = {
  widgets: [],
  deleteWidgetsIds: [],
  widgetContentSizes: {},
};
const slice = createSlice({
  name: 'widgetNavigation',
  initialState,
  reducers: {
    ...commonReducers<WidgetNavigationState, WidgetNavigationProperties>(),
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetNavigationActions = slice.actions;

export const widgetNavigationReducer = slice.reducer;

export const widgetNavigationGetters: CommonWidgetGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetNavigation.widgets,
};
