import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetRecentActivityState, WidgetRecentActivityProperties, WidgetRecentActivityGetters } from './types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetRecentActivityState = {
  widgets: [],
  deleteWidgetsIds: [],
  viewPage: 0,
  viewDocuments: [],
};
const slice = createSlice({
  name: 'widgetRecentActivity',
  initialState,
  reducers: {
    ...commonReducers<WidgetRecentActivityState, WidgetRecentActivityProperties>(),
    setViewPage: (state, action) => {
      state.viewPage = action.payload;
    },
    setViewDocuments: (state, action) => {
      state.viewDocuments = action.payload;
    },
    resetView: state => {
      state.viewPage = 0;
      state.viewDocuments = [];
    },
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetRecentActivityActions = slice.actions;

export const widgetRecentActivityReducer = slice.reducer;

export const widgetRecentActivityGetters: WidgetRecentActivityGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetRecentActivity.widgets,
  getViewPage: (state: RootState) => state.widgets.widgetRecentActivity.viewPage,
  getViewDocuments: (state: RootState) => state.widgets.widgetRecentActivity.viewDocuments,
};
