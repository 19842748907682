import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import faktProNormalWoff from 'assets/fonts/FaktPro-Normal.woff';
import faktProNormalWoff2 from 'assets/fonts/FaktPro-Normal.woff2';
import faktProBoldWoff from 'assets/fonts/FaktPro-Bold.woff';
import faktProBoldWoff2 from 'assets/fonts/FaktPro-Bold.woff2';

export const MuiCssBaseline = (theme: Theme): Components['MuiCssBaseline'] => ({
  // Object notation does not allow multiple @font-face rules, see https://github.com/mui-org/material-ui/issues/24966
  styleOverrides: `
    @font-face {
      font-family: 'FaktPro';
      src: url('${faktProNormalWoff2}') format('woff2'), url('${faktProNormalWoff}') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'FaktPro';
      src: url('${faktProBoldWoff2}') format('woff2'), url('${faktProBoldWoff}') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }
    body: {
      background: ${theme.palette.background.default};
    }
    #root {
      height: 100vh;
    }
    
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #979797;
      border-radius: 100px;
    }
  `,
});
