import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetMembersState, WidgetMembersProperties } from './types';
import { CommonWidgetGetters } from '../types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetMembersState = {
  widgets: [],
  deleteWidgetsIds: [],
};
const slice = createSlice({
  name: 'widgetMembers',
  initialState,
  reducers: {
    ...commonReducers<WidgetMembersState, WidgetMembersProperties>(),
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetMembersActions = slice.actions;

export const widgetMembersReducer = slice.reducer;

export const widgetMembersGetters: CommonWidgetGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetMembers.widgets,
};
