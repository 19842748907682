import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { WidgetDocumentState, WidgetDocumentProperties, WidgetDocumentGetters } from './types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetDocumentState = {
  widgets: [],
  deleteWidgetsIds: [],
  viewPage: 0,
  viewDocuments: [],
};
const slice = createSlice({
  name: 'widgetDocument',
  initialState,
  reducers: {
    ...commonReducers<WidgetDocumentState, WidgetDocumentProperties>(),
    setMediaToUpload: (state, action: PayloadAction<{ media: File[] | undefined }>) => {
      state.mediaToUpload = action.payload.media;
    },
    setViewPage: (state, action) => {
      state.viewPage = action.payload;
    },
    setViewDocuments: (state, action) => {
      state.viewDocuments = action.payload;
    },
    resetView: state => {
      state.viewPage = 0;
      state.viewDocuments = [];
    },
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetDocumentActions = slice.actions;

export const widgetDocumentReducer = slice.reducer;

export const widgetDocumentGetters: WidgetDocumentGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetDocument.widgets,
  getViewPage: (state: RootState) => state.widgets.widgetDocument.viewPage,
  getViewDocuments: (state: RootState) => state.widgets.widgetDocument.viewDocuments,
};
