import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiTypography = (theme: Theme): Components['MuiTypography'] => ({
  styleOverrides: {
    h1: {
      fontSize: '1.62rem',
      fontFamily: 'FaktPro',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.secondary.dark,
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      color: '#797071',
    },
    h5: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      color: theme.palette.text.secondary,
    },
    h6: {
      color: '#000000',
    },
    caption: {
      fontSize: '13px',
    },
  },
});
