import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authReducer } from 'features/auth/store/authSlice';
import { unauthorizedMiddleware } from 'features/auth/store/authMiddleware';
import { contactReducer } from 'features/contacts/store/contactSlice';
import { sidebarReducer } from 'features/core/store/sidebar';
import { widgetsReducer } from 'features/dashboard/store/widget';
import { searchReducer } from 'features/core/store/search/slice';
import { baseApi } from 'store/api/baseApi';
import { spaceReducer } from 'features/page/store/spaceSlice';
import { pageReducer } from 'features/page/store/pageSlice';
import { mediaReducer } from 'features/documents/store/mediaSlice';
import { pollingReducer } from 'features/monitor/store/pollingSlice';
import { editorReducer } from '../features/editor/store/editorSlice';
import { contactFiltersMiddleware } from '../features/contacts/store/contactFiltersMiddleware';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authReducer,
    space: spaceReducer,
    page: pageReducer,
    contact: contactReducer,
    media: mediaReducer,
    sidebar: sidebarReducer,
    widgets: widgetsReducer,
    search: searchReducer,
    polling: pollingReducer,
    editor: editorReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(unauthorizedMiddleware, contactFiltersMiddleware, baseApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
