import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiFilledInput = (theme: Theme): Components['MuiFilledInput'] => ({
  defaultProps: {
    margin: 'dense',
  },
  styleOverrides: {
    root: {
      background: theme.palette.background.paper,
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
      boxShadow: 'inset 0px 1px 1px 0px rgb(0 0 0 / 20%), inset 0px 1px 3px 1px rgb(0 0 0 / 12%)',
      '&:hover, &:focus, &.Mui-focused': {
        background: theme.palette.background.paper,
      },
      '&:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      },
    },
  },
});
