import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { WidgetWgState, WidgetWgProperties, WidgetWgGetters } from './types';
import { commonReducers, commonExtraHandlers } from '../common';
import { Widget as W } from '../types';

const initialState: WidgetWgState = {
  widgets: [],
  deleteWidgetsIds: [],
  isOpenTopicsSettings: false,
  activeTopicFilters: {},
  widgetContentSizes: {},
};

const slice = createSlice({
  name: 'widgetWg',
  initialState,
  reducers: {
    ...commonReducers<WidgetWgState, WidgetWgProperties>({
      set: (state: WidgetWgState, wgWidgets: W<WidgetWgProperties>[]) => {
        wgWidgets.forEach(wg => {
          state.activeTopicFilters[wg.id] = wg.properties.topicIds;
        });
      },
    }),
    setMediaToUpload: (state: WidgetWgState, action: PayloadAction<{ media: File[] | undefined }>) => {
      state.mediaToUpload = action.payload.media;
    },
    setIsOpenTopicsSettings: (state: WidgetWgState, action: PayloadAction<boolean>) => {
      state.isOpenTopicsSettings = action.payload;
    },
    setActiveTopicFilters: (state: WidgetWgState, action: PayloadAction<{ widgetId: string; items: string[] }>) => {
      state.activeTopicFilters[action.payload.widgetId] = action.payload.items;
    },
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetWgActions = slice.actions;

export const widgetWgReducer = slice.reducer;

export const widgetWgGetters: WidgetWgGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetWg.widgets,
  getIsOpenTopicsSettings: (state: RootState) => state.widgets.widgetWg.isOpenTopicsSettings,
  getActiveTopicFilters: (state: RootState) => state.widgets.widgetWg.activeTopicFilters,
};
