import { CaseReducer } from '@reduxjs/toolkit';
import { Breakpoint } from '@mui/material/styles';
import { Layout } from 'react-grid-layout';

import { RootState } from 'store/store';
import { Block } from 'features/page/store/types';
import { Media } from 'features/documents/store/mediaService';
import { WidgetShareProperties } from 'features/dashboard/store/widget/widgetShare/types';
import { Permission } from 'features/auth/store/permissionService';
import {
  WidgetClippingsProperties,
  WidgetCumulioProperties,
  WidgetDocumentProperties,
  WidgetNavigationProperties,
  WidgetRecentActivityProperties,
  WidgetTextProperties,
  WidgetWgProperties,
  WidgetPushNewsProperties,
  WidgetFavoritesProperties,
  WidgetInfoProperties,
  WidgetTopicsProperties,
  WidgetContentSize,
} from '.';

// Widget Properties
export enum WidgetType {
  TEXT = 'text',
  NAVIGATION = 'navigation',
  CLIPPINGS = 'clippings',
  WG = 'wg',
  DOCUMENT = 'document',
  CUMULIO = 'cumulio',
  RECENT_ACTIVITY = 'activity',
  PUSH_NEWS = 'news',
  FAVORITES = 'favorites',
  SHARE = 'share',
  MEMBERS = 'members',
  INFO = 'info',
  TOPICS = 'topics',
}

export enum WidgetNavigationContentLayout {
  SIMPLE = 'simple',
  EXTENDED = 'extended',
}

export interface WidgetMedia extends Media {}

export interface WidgetProperties {
  title: string;
  content?: any;
  grid: object;
  layout: {
    [b in Breakpoint]?: Layout;
  };
}

export interface Widget<P extends WidgetProperties> extends Block<P> {
  type: WidgetType;
}

// Widget getters
export interface CommonWidgetGetters {
  getWidgets: (state: RootState) => Widget<WidgetProperties>[];
}

// Widget State
export interface WidgetState<T> {
  widgets: T[];
  deleteWidgetsIds: string[];
  mediaToUpload?: Array<File | undefined>;
}

// Widget reducers
export interface WidgetCommonReducers<ST, P extends WidgetProperties> {
  set: CaseReducer<ST, { payload: Widget<P>[]; type: string }>;
  add: CaseReducer<ST, { payload: Widget<P>; type: string }>;
  update: CaseReducer<ST, { payload: Partial<Widget<P>>; type: string }>;
  delete: CaseReducer<ST, { payload: string; type: string }>;
  clear: CaseReducer<ST, { payload: undefined; type: string }>;
  setMediaToUpload?: CaseReducer<ST, { payload: File[] | undefined; type: string }>;
}

export interface CommonReducersArgs<ST, PR extends WidgetProperties> {
  set?: (state: ST, widgets: Widget<PR>[]) => void;
}

export interface WidgetCommonState {
  layouts: {
    [b in Breakpoint]?: Layout[];
  };
  temporaryLayouts: {
    [b in Breakpoint]?: Layout[];
  };
  isOpenFormModal: boolean;
  currentWidgetId: string | null;
  isFormLoading: boolean;
  isUpdateItemLoading: boolean;
  isDeleteItemLoading: boolean;
  isLoading: boolean;
  isItemLoading: boolean;
  uploadDocumentModal: {
    isOpen: boolean;
    widgetId: string;
    type: string;
    data: File[];
  };
  widgetContentSizes: {
    [key: string]: WidgetContentSize;
  };
  permissions: {
    common: Permission[];
    current: Permission[];
  };
}

export interface NewInitializeObject {
  [WidgetType.TEXT]: Widget<WidgetTextProperties>[];
  [WidgetType.INFO]: Widget<WidgetInfoProperties>[];
  [WidgetType.TOPICS]: Widget<WidgetTopicsProperties>[];
  [WidgetType.DOCUMENT]: Widget<WidgetDocumentProperties>[];
  [WidgetType.CLIPPINGS]: Widget<WidgetClippingsProperties>[];
  [WidgetType.NAVIGATION]: Widget<WidgetNavigationProperties>[];
  [WidgetType.CUMULIO]: Widget<WidgetCumulioProperties>[];
  [WidgetType.WG]: Widget<WidgetWgProperties>[];
  [WidgetType.RECENT_ACTIVITY]: Widget<WidgetRecentActivityProperties>[];
  [WidgetType.PUSH_NEWS]: Widget<WidgetPushNewsProperties>[];
  [WidgetType.SHARE]: Widget<WidgetShareProperties>[];
  [WidgetType.FAVORITES]: Widget<WidgetFavoritesProperties>[];
  [WidgetType.MEMBERS]: Widget<WidgetFavoritesProperties>[];
}

// Types from other slices
export * from './widgetDocument/types';
export * from './widgetText/types';
export * from './widgetClippings/types';
export * from './widgetNavigation/types';
export * from './widgetCumulio/types';
export * from './widgetWg/types';
export * from './widgetRecentActivity/types';
export * from './widgetPushNews/types';
export * from './widgetShare/types';
export * from './widgetFavorites/types';
export * from './widgetMembers/types';
export * from './widgetInfo/types';
export * from './widgetTopics/types';
