import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';
import {
  getCurrentUserSpaceIds,
  getIsContactsUser,
  getIsCugAdmin,
  getIsCugUser,
  getIsGlobalAdmin,
} from 'features/auth/store/authSlice';
import { getHasAccessToGeneralSpace } from 'features/page/store/spaceSlice';
import { SidebarCheck, SidebarDataMeth } from 'features/core/store/sidebar/utils';
import { KeyOfMenuItem, MenuItem, SidebarState, StaticMenuItemName } from './types';

export const DEFAULT_DASHBOARD_ID = '72905cea-989c-411a-b288-d203742e7bd8';
export const PAGE_TEMPLATE_ID = '6bb268cb-0fbe-4082-a390-efc859d19760';
export const WORKING_GROUPS_ITEM_ID = 'd0f5bb1f-e116-4305-8ca1-4b6ef26be7be';
export const DASHBOARD_ITEM_ID = 'db8b8cd5-110a-4be4-bdd8-0bc14509e285';
export const GENERAL_ITEM_ID = '61be352c-943f-45c2-94f9-ebcf7a927de4';
export const CONTACTS_ITEM_ID = '484c7db7-a95a-4b5b-a630-50ac09c64e01';
export const SETTINGS_ITEM_ID = '484c7db7-a95a-4b5b-a630-50ac09c64e02';

const staticMenuItems = [
  {
    id: DASHBOARD_ITEM_ID,
    name: StaticMenuItemName.DASHBOARD,
    path: '/dashboard',
    iconComponentName: 'Dashboard',
    parent: null,
  },
  {
    id: GENERAL_ITEM_ID,
    name: StaticMenuItemName.GENERAL,
    path: `/page/${GENERAL_ITEM_ID}`,
    iconComponentName: 'General',
    title: 'General Informations',
    children: [],
    parent: null,
  },
  {
    id: WORKING_GROUPS_ITEM_ID,
    name: StaticMenuItemName.WORKING_GROUPS,
    path: '/wg',
    iconComponentName: 'WorkOutline',
    title: 'Working Groups',
    children: [],
    parent: null,
    defaultPath: '/wg',
  } as Partial<MenuItem>,
  // {
  //   name: StaticMenuItemName.MESSAGES,
  //   path: '/messages',
  //   iconComponentName: 'SmsOutlined',
  //   parent: null,
  // },
  {
    id: CONTACTS_ITEM_ID,
    name: StaticMenuItemName.CONTACTS,
    path: '/contacts',
    iconComponentName: 'PeopleOutline',
    parent: null,
  },
  {
    id: SETTINGS_ITEM_ID,
    name: StaticMenuItemName.SETTINGS,
    path: '/settings',
    iconComponentName: 'SettingsOutlined',
    parent: null,
    children: [],
  },
] as MenuItem[];

const slice = createSlice({
  name: 'sidebar',
  initialState: {
    menuItems: staticMenuItems,
    currentMenuItem: null,
    isSubMenuOpen: false,
    isFetch: false,
  } as SidebarState,
  reducers: {
    setMenuItems: (state: SidebarState, action: PayloadAction<MenuItem[]>) => {
      // TODO there should be a better solution for this
      // Menu items are recomputed once the roles of the user are available => some previously filtered static items should now be displayed
      const mergedArray = [...staticMenuItems, ...action.payload];
      state.menuItems = [...mergedArray.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
    },
    setCurrentMenuItem: (state: SidebarState, action: PayloadAction<MenuItem | null>) => {
      state.currentMenuItem = action.payload;
    },
    setIsSubMenuOpen: (state: SidebarState, action: PayloadAction<boolean>) => {
      state.isSubMenuOpen = action.payload;
    },
    updateMenuItemByKeyValue: (
      state: SidebarState,
      action: PayloadAction<{ item: Partial<MenuItem>; key: KeyOfMenuItem; value: any; ignoredKeys?: KeyOfMenuItem[] }>,
    ) => {
      const { key, value, item, ignoredKeys = [] } = action.payload;
      const obj = SidebarDataMeth.findMenuItemByKeyValue(state.menuItems, key, value);
      if (obj) {
        Object.keys(item).forEach(itemKey => {
          const k = itemKey as keyof MenuItem;
          if (k in obj && ignoredKeys.findIndex(ignored => ignored === itemKey) === -1) {
            obj[k] = item[k];
          }
        });
      }
    },
    expandAllParentsAndToggleSubmenu: (
      state: SidebarState,
      action: PayloadAction<{ pageId: string; openSubmenu: boolean }>,
    ) => {
      const child = SidebarDataMeth.findMenuItemByKeyValue(state.menuItems, 'id', action.payload.pageId);
      if (child) {
        const parents = SidebarDataMeth.findAllParentsOfChild(state.menuItems, child);
        parents.forEach((el: MenuItem) => {
          el.expanded = true;
        });
        // Safari iOS does not like `parents.at(-1)`, see CUG-322
        const menuItemParent = SidebarDataMeth.findMenuItemByKeyValue(
          state.menuItems,
          'id',
          parents[parents.length - 1]?.id || child.parent,
        );
        if (menuItemParent) {
          state.currentMenuItem = menuItemParent;
        } else {
          state.currentMenuItem = parents[parents.length - 1] ?? null;
        }
        if (action.payload.openSubmenu) {
          state.isSubMenuOpen = true;
        }
      }
    },
    collapseAllExpandedItems: (state: SidebarState) => {
      SidebarDataMeth.deepForEach(state.menuItems, (item: MenuItem) => {
        if (item.expanded) {
          item.expanded = false;
        }
        return false;
      });
    },
    collapseParentChildrenByKeyValue: (
      state: SidebarState,
      action: PayloadAction<{ key: KeyOfMenuItem; value: any }>,
    ) => {
      const { key, value } = action.payload;
      const obj = SidebarDataMeth.findMenuItemByKeyValue(state.menuItems, key, value);
      if (obj) {
        SidebarDataMeth.deepForEach(obj.children as MenuItem[], (item: MenuItem) => {
          item.expanded = false;
          return false;
        });
      }
    },
    collapseAllStaticItems: (state: SidebarState) => {
      state.menuItems.forEach(el => {
        if (SidebarCheck.isStaticPageOfStaticItem(el.name as StaticMenuItemName) && el.expanded) {
          el.expanded = false;
        }
      });
    },
    collapseAllNeighborsItems: (
      state: SidebarState,
      action: PayloadAction<{ item: MenuItem; rule?: (item: MenuItem) => boolean }>,
    ) => {
      const parent = SidebarDataMeth.findMenuItemByKeyValue(state.menuItems, 'id', action.payload.item.parent);
      if (parent) {
        SidebarDataMeth.deepForEach(parent.children as MenuItem[], (item: MenuItem) => {
          if (action.payload.rule && action.payload.rule(item)) {
            item.expanded = false;
            return false;
          }
          if (item.path !== action.payload.item.path) {
            item.expanded = false;
          }
          return false;
        });
      }
    },
    setIsFetch: (state: SidebarState, action: PayloadAction<boolean>) => {
      state.isFetch = action.payload;
    },
    insertSubMenuItem: (state: SidebarState, action: PayloadAction<Partial<MenuItem>>) => {
      const parent = SidebarDataMeth.findMenuItemByKeyValue(state.menuItems, 'id', action.payload.parent);
      const parents = SidebarDataMeth.findAllParentsOfChild(state.menuItems, action.payload);
      if (!parent?.children) {
        parent!.children = [];
      }
      if (parent) {
        parent.children.splice(parent.children.length, 0, {
          id: action.payload.id,
          name: action.payload.name,
          parent: action.payload.parent,
          path: parents.map(el => el.id).join('.'),
          weight: parent.children!.length + 1,
        });
      }
    },
  },
});

export const sidebarActions = slice.actions;

export const sidebarReducer = slice.reducer;

const getUnfilteredMenuItems = (state: RootState) => state.sidebar.menuItems;

export const sidebarGetters = {
  getMenuItems: createSelector(
    getUnfilteredMenuItems,
    getIsGlobalAdmin,
    getIsCugAdmin,
    getIsContactsUser,
    getIsCugUser,
    getHasAccessToGeneralSpace,
    getCurrentUserSpaceIds,
    (menuItems, isGlobalAdmin, isCugAdmin, isContactsUser, isCugUser, hasAccessToGeneralSpace, spaceIds) =>
      menuItems.filter(m => {
        if (m.id === CONTACTS_ITEM_ID) {
          return isContactsUser;
        }
        if (m.id === SETTINGS_ITEM_ID) {
          return isGlobalAdmin;
        }
        if (m.id === GENERAL_ITEM_ID) {
          return hasAccessToGeneralSpace;
        }
        if (m.id === DASHBOARD_ITEM_ID) {
          return isCugUser;
        }
        if (m.id === WORKING_GROUPS_ITEM_ID) {
          return isCugAdmin || (hasAccessToGeneralSpace ? spaceIds?.length > 1 : spaceIds?.length > 0);
        }
        return true;
      }),
  ),
  getCurrentMenuItem: (state: RootState) => state.sidebar.currentMenuItem,
  getIsSubMenuOpen: (state: RootState) => state.sidebar.isSubMenuOpen,
  getIsFetch: (state: RootState) => state.sidebar.isFetch,
};
