import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type EditorState = {
  uploadMediaBulk: File[];
};

const initialState = {
  uploadMediaBulk: [],
};

const slice = createSlice({
  name: 'editor',
  initialState: initialState as EditorState,
  reducers: {
    addUploadMediaBulk: (state, { payload: uploadMedia }: PayloadAction<[]>) => {
      state.uploadMediaBulk = uploadMedia;
    },
  },
});

export const { addUploadMediaBulk } = slice.actions;

export const editorReducer = slice.reducer;

export const getUploadMediaBulk = (state: RootState) => state.editor.uploadMediaBulk;
