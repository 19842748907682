import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { Media } from 'features/documents/store/mediaService';

type MediaState = {
  uploadTemporaryMedia: Media[];
};

const initialState = {
  uploadTemporaryMedia: [],
};

const slice = createSlice({
  name: 'media',
  initialState: initialState as MediaState,
  reducers: {
    addUploadTemporaryMedia: (state, { payload: media }: PayloadAction<Media>) => {
      state.uploadTemporaryMedia.push(media);
    },
    resetUploadTemporaryMedia: state => {
      state.uploadTemporaryMedia = [];
    },
  },
});

export const { addUploadTemporaryMedia, resetUploadTemporaryMedia } = slice.actions;

export const mediaReducer = slice.reducer;

export const getUploadTemporaryMedia = (state: RootState) => state.media.uploadTemporaryMedia;
