import { Components } from '@mui/material/styles/components';

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      padding: '10px 15px',
      boxShadow: 'none',
      fontSize: '13px',
    },
    textSecondary: {
      color: '#000000',
    },
  },
  variants: [
    {
      props: {
        variant: 'contained',
      },
      style: {
        color: '#ffffff',
      },
    },
    {
      props: {
        color: 'secondary',
      },
      style: {
        background: '#404040',
      },
    },
  ],
};
