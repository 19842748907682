import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiDrawer = (theme: Theme): Components['MuiDrawer'] => ({
  styleOverrides: {
    paper: {
      background: theme.palette.background.default,
    },
  },
});
