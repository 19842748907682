import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';
import { Role } from 'features/core/store/roleService';
import { User } from 'features/core/store/user';

type AuthState = {
  user: User | null;
  roles: Role[];
  isAuthenticated: boolean;
  loginDestination: string;
};

const initialState = {
  user: null,
  roles: [],
  isAuthenticated: !!localStorage.getItem('accessToken'),
  loginDestination: '/',
};

const slice = createSlice({
  name: 'auth',
  initialState: initialState as AuthState,
  reducers: {
    setUser: (state, { payload: user }: PayloadAction<User>) => {
      state.user = user;
      state.roles = user.roles || [];
    },
    setIsAuthenticated: (state, { payload: isAuthenticated }: PayloadAction<boolean>) => {
      state.isAuthenticated = isAuthenticated;
    },
    setLoginDestination: (state, { payload }) => {
      state.loginDestination = payload;
    },
  },
});

export const { setUser, setIsAuthenticated, setLoginDestination } = slice.actions;

export const authReducer = slice.reducer;

export const getIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;

export const getLoginDestination = (state: RootState) => state.auth.loginDestination;

export const getCurrentUser = (state: RootState) => state.auth.user;

export const getCurrentUserRoles = (state: RootState) => state.auth.roles;

export const getCurrentUserSpaceIds = createSelector(
  getCurrentUserRoles,
  roles => roles?.map(r => r.spaceId)?.filter(id => id !== null) || [],
);

export const getCurrentUserGlobalRoleNames = createSelector(
  getCurrentUserRoles,
  roles => roles?.filter(r => r.spaceId === null)?.map(r => r.machineName) || [],
);

// Temporary quick hack for CUG-397. To be removed once the permission system is ready
const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_CONTACTS_ADMIN = 'ROLE_CONTACTS_ADMIN';
const ROLE_CONTACTS_USER = 'ROLE_CONTACTS_USER';
const ROLE_CUG_ADMIN = 'ROLE_CUG_ADMIN';
const CONTACT_ADMIN_ROLES = [ROLE_ADMIN, ROLE_CONTACTS_ADMIN];
const CONTACT_USER_ROLES = [ROLE_ADMIN, ROLE_CONTACTS_ADMIN, ROLE_CONTACTS_USER];
const CUG_ADMIN_ROLES = [ROLE_ADMIN, ROLE_CUG_ADMIN];
const GLOBAL_ADMIN_ROLES = [ROLE_ADMIN];
export const getIsGlobalAdmin = createSelector(
  getCurrentUserGlobalRoleNames,
  roleNames => roleNames.some(a => GLOBAL_ADMIN_ROLES.includes(a)) || false,
);
export const getIsContactsAdmin = createSelector(
  getCurrentUserGlobalRoleNames,
  roleNames => roleNames.some(a => CONTACT_ADMIN_ROLES.includes(a)) || false,
);
export const getIsContactsUser = createSelector(
  getCurrentUserGlobalRoleNames,
  roleNames => roleNames.some(a => CONTACT_USER_ROLES.includes(a)) || false,
);
export const getIsCugAdmin = createSelector(
  getCurrentUserGlobalRoleNames,
  roleNames => roleNames.some(a => CUG_ADMIN_ROLES.includes(a)) || false,
);
export const getIsCugUser = createSelector(
  getCurrentUserSpaceIds,
  getIsCugAdmin,
  (spaceIds, isCugAdmin) => spaceIds?.length > 0 || isCugAdmin,
);
