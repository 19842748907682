export const parsePayload = (jwt: string): any => {
  const payloadBase64 = jwt.split('.')[1];
  if (payloadBase64) {
    return JSON.parse(window.atob(payloadBase64));
  }
  return {};
};

export const isValidToken = (jwt: string): boolean => {
  const payload = parsePayload(jwt);
  const { exp } = payload;
  if (!exp) {
    return false;
  }
  const now = new Date();
  return now.getTime() <= exp * 1000;
};

export const parseAuthorities = (jwt: string): string[] => {
  const payload = parsePayload(jwt);
  const { authorities } = payload;
  return authorities || [];
};
