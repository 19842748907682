import { Menu } from 'features/spaces/store/menuService';
import { MenuItem, MenuItemChild, KeyOfMenuItem, StaticMenuItemName } from '../types';

export class SidebarDataMeth {
  private static searchTree(
    element: MenuItemChild,
    key: KeyOfMenuItem,
    value: any,
    isInclude?: boolean,
  ): MenuItemChild | undefined {
    // @ts-ignore
    if (element[key] === value) {
      return element;
      /* eslint-disable */
    } else if (isInclude && element[key].endsWith(value)) return element;
    /* eslint-disable */ else if (Array.isArray(element.children)) {
      let result;
      /* eslint-disable */
      for (let i = 0; result === undefined && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], key, value, isInclude);
      }
      return result;
    }
    return;
  }

  private static dataTreeToMenuItems(
    data: Menu[],
    favoriteGroupName?: StaticMenuItemName | null,
    pathPropName?: KeyOfMenuItem,
    isFavorite?: boolean,
  ): MenuItem[];
  private static dataTreeToMenuItems(data: Menu[], ...args: any[]): MenuItem[] {
    const arr: MenuItem[] = [];

    for (let i = 0; i < data.length; i++) {
      const dataItem: Menu = data[i];

      const menuItem: MenuItem = {
        id: dataItem.id,
        name: dataItem.name,
        title: dataItem.title,
        path: dataItem.id,
        focusArea: dataItem.focusArea,
        parent: dataItem.parent,
        weight: dataItem.weight,
        longPath: dataItem.path,
        children: Array.isArray(dataItem.children)
          ? this.dataTreeToMenuItems(dataItem.children, null, args[1], args[0] === dataItem.name ? false : undefined)
          : [],
        expanded: false,
        favorite: args[2],
        createdAt: dataItem.createdAt,
        lastModifiedAt: dataItem.lastModifiedAt,
        space: dataItem.space,
        isPublished: dataItem.isPublished,
      };
      if (args[1]) {
        arr.push({ ...menuItem, [args[1]]: dataItem.path });
      } else {
        arr.push(menuItem);
      }
    }

    return arr;
  }

  static convertDataToMenuItems(
    data: Menu[],
    { favoriteGroupName, pathPropName }: { favoriteGroupName?: StaticMenuItemName; pathPropName?: KeyOfMenuItem },
  ): MenuItem[] {
    const arr: Menu[] = [];
    const copiedData = data.map(el => ({ ...el }));

    for (let i = 0; i < copiedData.length; i++) {
      copiedData[i].children = copiedData.filter(el => el.parent === data[i].id);
      if (copiedData[i].parent === null) {
        arr.push(copiedData[i]);
      }
    }
    return this.dataTreeToMenuItems(arr, favoriteGroupName, pathPropName);
  }

  static makeExpandedMenuItems(items: MenuItem[], expandedItems: MenuItem[]): MenuItem[] {
    const arr: MenuItem[] = [];

    for (let i = 0; i < items.length; i++) {
      const newItem: MenuItem = {
        ...items[i],
        children: this.makeExpandedMenuItems(items[i].children as MenuItem[], expandedItems),
        expanded: this.findMenuItemByKeyValue(expandedItems, 'id', items[i].id)?.expanded || false,
      };
      arr.push(newItem);
    }
    return arr;
  }

  static deepForEach(
    items: MenuItem[],
    cb: (item: MenuItem, parent?: MenuItem) => boolean | undefined, // return {true} to break cycle
    parent?: MenuItem,
  ): void {
    for (let i = 0; i < items.length; i++) {
      if (cb(items[i], parent)) {
        break;
      }

      if (items[i].children?.length) {
        SidebarDataMeth.deepForEach(items[i].children as MenuItem[], cb, items[i]);
      }
    }
  }

  static findAllParentsOfChild(items: MenuItem[], child: Partial<MenuItem>): MenuItem[] {
    let arr: MenuItem[] = [];
    const obj = SidebarDataMeth.findMenuItemByKeyValue(items, 'path', child.parent, true);
    if (obj && !obj.parent) {
      arr.push(obj);
    } else if (obj) {
      arr.push(obj);
      arr = arr.concat(SidebarDataMeth.findAllParentsOfChild(items, obj));
    }
    return arr;
  }

  static makeActiveAllParentsOfChild(items: MenuItem[], child: Partial<MenuItem>): MenuItem[] {
    return SidebarDataMeth.findAllParentsOfChild(items, child).map(item => ({ ...item, active: true }));
  }

  static findMenuItemByKeyValue(
    items: MenuItem[],
    key: KeyOfMenuItem,
    value: any,
    isInclude?: boolean,
  ): MenuItem | undefined {
    const arr: MenuItem[] = [];

    /* eslint-disable */
    for (let i = 0; i < items.length; i++) {
      const result = this.searchTree(items[i], key, value, isInclude) as MenuItem;

      if (result) {
        arr.push(result);
        break;
      }
    }

    return arr[0];
  }

  static getParentFromNesting(items: MenuItem[], child: MenuItem): MenuItem | undefined {
    let p: MenuItem | undefined;

    this.deepForEach(items, (item, parent) => {
      if (child.path === item.path) {
        p = parent as MenuItem;
        return true;
      }
    });

    return p;
  }
}
