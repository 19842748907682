import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { WidgetTopicsState, WidgetTopicsProperties, WidgetTopicsGetters } from './types';
import { Widget as W } from '../types';
import { commonReducers, commonExtraHandlers } from '../common';

const initialState: WidgetTopicsState = {
  widgets: [],
  deleteWidgetsIds: [],
  isOpenTopicsSettings: false,
  activeTopicFilters: {},
};
const slice = createSlice({
  name: 'widgetTopics',
  initialState,
  reducers: {
    ...commonReducers<WidgetTopicsState, WidgetTopicsProperties>({
      set: (state: WidgetTopicsState, wgWidgets: W<WidgetTopicsProperties>[]) => {
        wgWidgets.forEach(wg => {
          state.activeTopicFilters[wg.id] = wg.properties.topicIds;
        });
      },
    }),
    setIsOpenTopicsSettings: (state: WidgetTopicsState, action: PayloadAction<boolean>) => {
      state.isOpenTopicsSettings = action.payload;
    },
    setActiveTopicFilters: (state: WidgetTopicsState, action: PayloadAction<{ widgetId: string; items: string[] }>) => {
      state.activeTopicFilters[action.payload.widgetId] = action.payload.items;
    },
  },
  extraReducers: builder => {
    // @ts-ignore
    builder.addCase('widget/setLayouts', commonExtraHandlers.updateLayouts);
    // @ts-ignore
    builder.addCase('widget/setTemporaryLayouts', commonExtraHandlers.updateLayouts);
  },
});

export const widgetTopicsActions = slice.actions;

export const widgetTopicsReducer = slice.reducer;

export const widgetTopicsGetters: WidgetTopicsGetters = {
  getWidgets: (state: RootState) => state.widgets.widgetTopics.widgets,
  getActiveTopicFilters: (state: RootState) => state.widgets.widgetTopics.activeTopicFilters,
};
