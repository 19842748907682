import { Breakpoint } from '@mui/material/styles';
import { PayloadAction } from '@reduxjs/toolkit';
import { Layout, Layouts } from 'react-grid-layout';
import {
  WidgetClippingsProperties,
  WidgetCumulioProperties,
  WidgetDocumentProperties,
  WidgetNavigationProperties,
  WidgetTextProperties,
  WidgetWgProperties,
  WidgetRecentActivityProperties,
  WidgetPushNewsProperties,
  WidgetFavoritesProperties,
  WidgetShareProperties,
  WidgetTopicsProperties,
} from '.';

import {
  Widget,
  WidgetCommonReducers,
  WidgetCommonState,
  WidgetDocumentState,
  WidgetTextState,
  WidgetClippingsState,
  WidgetNavigationState,
  WidgetCumulioState,
  WidgetTopicsState,
  WidgetFavoritesState,
  WidgetWgState,
  WidgetRecentActivityState,
  WidgetPushNewsState,
  WidgetShareState,
  WidgetType,
  WidgetProperties,
  WidgetState,
  CommonReducersArgs,
} from './types';
import { validateAddWidget, validateWidgetType } from './utils';

export function commonReducers<ST, PR extends WidgetProperties>(
  args?: CommonReducersArgs<ST, PR>,
): WidgetCommonReducers<ST, PR> {
  return {
    set: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.widgets = action.payload;
        if (args && args.set) args.set(state, action.payload);
      }
    },
    add: (state, action) => {
      if (validateAddWidget(state.widgets, action.payload)) {
        state.widgets.push(action.payload);
      }
    },
    update: (state, action) => {
      const propertiesKey = 'properties';
      const obj = state.widgets.find(w => w.id === action.payload.id);
      if (obj) {
        Object.keys(action.payload).forEach(key => {
          /* eslint-disable */
          if (key in obj) {
            if (key === propertiesKey) {
              obj[key] = {
                // @ts-ignore
                ...action.payload[key],
                grid: obj[key].grid,
                layout: obj[key].layout,
              };
            } else {
              // @ts-ignore
              obj[key] = action.payload[key];
            }
          }
        });
      }
    },
    delete: (state, action) => {
      state.deleteWidgetsIds.push(action.payload);
    },
    clear: state => {
      if (state.deleteWidgetsIds.length > 0) {
        state.deleteWidgetsIds.length = 0;
      }
    },
    setMediaToUpload: () => {},
    // @ts-ignore
    changeWidgetContentSize: (
      state: WidgetNavigationState,
      { payload }: PayloadAction<{ widgetId: string; value: number }>,
    ) => {
      const { widgetId, value } = payload;
      state.widgetContentSizes[widgetId] = {
        ...state.widgetContentSizes[widgetId],
        contentHeight: value,
      };
    },
  };
}

export const commonExtraHandlers = {
  updateLayouts: (state: WidgetState<any>, { payload }: PayloadAction<Layouts>) => {
    Object.keys(payload).forEach(key => {
      payload[key].forEach((obj: Layout) => {
        const idx = state.widgets.findIndex(({ id }) => id === obj.i);
        if (idx > -1) {
          if (typeof state.widgets[idx].properties.layout !== 'object') {
            state.widgets[idx].properties.layout = {};
          }
          // @ts-ignore
          state.widgets[idx].properties.layout[key] = { ...obj };
        }
      });
    });
  },
};
export const commonWidgetsExtraHandlers = {
  setLayouts: (state: WidgetCommonState, { payload }: PayloadAction<Widget<WidgetProperties>[]>) => {
    payload.forEach(w => {
      const { layout } = w.properties;
      if (typeof layout !== 'object') return;
      Object.keys(layout).forEach((key: string) => {
        if (!Array.isArray(state.layouts[key as Breakpoint])) {
          state.layouts[key as Breakpoint] = [];
        }
        if (Object.keys(layout[key as Breakpoint]!).length) {
          state.layouts[key as Breakpoint]!.push(layout[key as Breakpoint]!);
        }
      });
    });
  },
  addLayouts: (state: WidgetCommonState, { payload }: PayloadAction<Widget<WidgetProperties>>) => {
    const { layout } = payload.properties;
    Object.keys(layout).forEach((key: string) => {
      if (typeof layout !== 'object') return;
      if (!Array.isArray(state.layouts[key as Breakpoint])) {
        state.layouts[key as Breakpoint] = [];
      }
      if (Object.keys(layout[key as Breakpoint]!).length) {
        state.layouts[key as Breakpoint]!.push(layout[key as Breakpoint]!);
      }
    });
  },
  updateLayouts: (state: WidgetCommonState, { payload }: PayloadAction<Widget<WidgetProperties>>) => {
    const { layout } = payload.properties;
    Object.keys(layout).forEach((key: string) => {
      if (typeof layout !== 'object') return;
      if (Array.isArray(state.layouts[key as Breakpoint])) {
        const idx = state.layouts[key as Breakpoint]!.findIndex(({ i }) => i === payload.id);
        if (idx > -1 && Object.keys(layout[key as Breakpoint]!).length) {
          state.layouts[key as Breakpoint]![idx] = layout[key as Breakpoint]!;
        } else {
          state.layouts[key as Breakpoint]!.push(layout[key as Breakpoint]!);
        }
      }
    });
  },
  deleteLayouts: (state: WidgetCommonState) => {
    state.layouts = {};
    state.temporaryLayouts = {};
  },
};

export class CommonActionsList {
  private [WidgetType.DOCUMENT]: WidgetCommonReducers<WidgetDocumentState, WidgetDocumentProperties>;
  private [WidgetType.TEXT]: WidgetCommonReducers<WidgetTextState, WidgetTextProperties>;
  private [WidgetType.CLIPPINGS]: WidgetCommonReducers<WidgetClippingsState, WidgetClippingsProperties>;
  private [WidgetType.NAVIGATION]: WidgetCommonReducers<WidgetNavigationState, WidgetNavigationProperties>;
  private [WidgetType.CUMULIO]: WidgetCommonReducers<WidgetCumulioState, WidgetCumulioProperties>;
  private [WidgetType.WG]: WidgetCommonReducers<WidgetWgState, WidgetWgProperties>;
  private [WidgetType.TOPICS]: WidgetCommonReducers<WidgetTopicsState, WidgetTopicsProperties>;
  private [WidgetType.RECENT_ACTIVITY]: WidgetCommonReducers<WidgetRecentActivityState, WidgetRecentActivityProperties>;
  private [WidgetType.PUSH_NEWS]: WidgetCommonReducers<WidgetPushNewsState, WidgetPushNewsProperties>;
  private [WidgetType.SHARE]: WidgetCommonReducers<WidgetShareState, WidgetShareProperties>;
  private [WidgetType.FAVORITES]: WidgetCommonReducers<WidgetFavoritesState, WidgetFavoritesProperties>;

  private widgetTypeValues = Object.values(WidgetType);

  constructor(actions: any) {
    Object.keys(actions).forEach(key => {
      const propName = this.generatedTypeValueFromOutside(key);
      if (propName) {
        this[propName] = this.filterCommonActions(actions[key]);
      }
    });
  }

  private generatedTypeValueFromOutside(actionsKey: string): WidgetType | undefined {
    const key = actionsKey.toLocaleLowerCase();

    if (key.startsWith('widget') && key.endsWith('actions')) {
      return this.widgetTypeValues.filter(value => key.includes(value))[0];
    }

    return;
  }

  private filterCommonActions(actions: any) {
    const obj: any = {};
    Object.keys(actions).filter(key => {
      if (key in commonReducers() && actions[key]) {
        obj[key] = actions[key];
      }
    });
    return obj;
  }

  getActionsByWidgetType(type: WidgetType) {
    if (!this[type]) {
      throw new Error('invalid type: WidgetType');
    }
    return this[type];
  }

  async getActionsByWidget(w: Partial<Widget<WidgetProperties>>) {
    if (!validateWidgetType(w)) {
      throw new Error('Invalid widget type');
    }

    return this[w.type!];
  }

  getAllActions() {
    return this.widgetTypeValues.map(propName => this[propName]);
  }
}
