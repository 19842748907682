import { Block } from 'features/page/store/blockService';
import { Widget as W, WidgetProperties, widgetsActions } from 'features/dashboard/store/widget';
import { FocusAreaType } from 'features/core/components/FocusArea';
import { Media } from 'features/documents/store/mediaService';
import { PagedRequestQueryParams, PagedResponse } from 'store/api/types';
import { baseApi } from 'store/api/baseApi';
import { Space } from 'features/spaces/store/spaceService';
import { Permission } from 'features/auth/store/permissionService';

export interface Page {
  id: string;
  name: string;
  parent?: string;
  lead: {};
  focusArea: FocusAreaType;
  blocks: Block[];
  space?: string;
  parentSpace?: Space; // is only set if the page is the frontPage of the space
  isPublished: boolean;
  lastModifiedAt: string;
  entityType: string;
  permissions: Permission[];
}

export const emptyPage: Page = {
  name: 'New page',
  lead: { content: 'test lead' },
  focusArea: 'patients',
  blocks: [],
  isPublished: true,
  lastModifiedAt: '',
  entityType: 'Page',
};

export type PageResponse = Page[];

export interface GetPageAttachmentsParams extends PagedRequestQueryParams {
  id: string;
  topicIds?: string[];
}

const extendedApi = baseApi.enhanceEndpoints({ addTagTypes: ['Page', 'Block', 'Media', 'Session'] }).injectEndpoints({
  endpoints: build => ({
    getPages: build.query<PageResponse, void>({
      query: () => '/api/rest/pages',
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: 'Page' as const, id })), 'Page'] : ['Page'],
    }),
    getRecursivePages: build.query<PageResponse, void>({
      query: () => `/api/rest/pages?isRecursive=true`,
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: 'Page' as const, id })), 'Page'] : ['Page'],
    }),
    getPage: build.query<Page, string>({
      query: id => `/api/rest/pages/${id}`,
      providesTags: (result, error, id) => [{ type: 'Page', id }],
      onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        queryFulfilled
          .then(({ data }) => {
            if (getState()?.page.currentPage?.id === id) {
              dispatch(widgetsActions.widgetInitialization(data.blocks as W<WidgetProperties>[]));
            }
          })
          // silently ignore
          .catch(() => {});
      },
    }),
    getPageSessions: build.query<Block[], { id: string }>({
      query: ({ id }) => `/api/rest/pages/${id}/wg/sessions`,
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: 'Block' as const, id })), 'Block'] : ['Block'],
    }),
    getPageAttachments: build.query<PagedResponse<Media>, GetPageAttachmentsParams>({
      query: ({ id, topicIds, page = 0, size = 20 }) =>
        `/api/rest/pages/${id}/wg/attachments?topicIds=${topicIds?.join(',') ?? ''}&page=${page}&size=${size}`,
      providesTags: (result, error, args) =>
        result
          ? [
              ...result.content.map(({ id }) => ({ type: 'Media' as const, id })),
              ...(args?.topicIds || []).map(id => ({ type: 'Media-Blocks' as const, id })),
              'Media',
            ]
          : ['Media'],
    }),
    createPage: build.mutation<Page, Partial<Page>>({
      query: body => ({
        url: `/api/rest/pages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Page'],
    }),
    updatePage: build.mutation<Page, Partial<Page>>({
      query: body => ({
        url: `/api/rest/pages/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Page', id }],
    }),
    deletePage: build.mutation<void, string>({
      query(id) {
        return {
          url: `/api/rest/pages/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Page', id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPagesQuery,
  useGetPageQuery,
  useGetPageSessionsQuery,
  useGetPageAttachmentsQuery,
  useGetRecursivePagesQuery,
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
  endpoints,
} = extendedApi;
