import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import reportWebVitals from 'reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { baseTheme } from 'theme/baseTheme';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { store } from 'store/store';
import { App } from 'features/core/components/App';
import { CustomRouter } from 'features/core/components/CustomRouter';

TagManager.initialize({ gtmId: 'GTM-MQBHSVWD' });

// eslint-disable-next-line import/prefer-default-export
export const history = createBrowserHistory();

const container = document.getElementById('root') as HTMLDivElement;
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CustomRouter history={history}>
        <ThemeProvider theme={baseTheme}>
          <ConfirmProvider>
            <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
              <ErrorBoundary
                fallbackRender={() => (
                  <div role="alert">
                    <div>Something went wrong. Please try to refresh the page or contact an administrator.</div>
                  </div>
                )}
              >
                <App />
              </ErrorBoundary>
            </SnackbarProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </CustomRouter>
    </Provider>
  </React.StrictMode>,
  container,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
